<template>
  <el-main class="rh-medform">
    <form-evaluationedit :routes="routes" />
  </el-main>
</template>
<script>
import FormEvaluationedit from "@/components/rh/formulaires/FormEvaluationedit";

export default {
  components: { "form-evaluationedit": FormEvaluationedit },

  computed: {
    routes() {
      const path = this.$route.path;
      const routeName = path.includes("tuteurs")
        ? "med_tuteurs_edit"
        : "med_pupilles_edit";

      return {
        tolist: {
          name: routeName,
          params: {
            idmed: this.$route.params.idmed,
            idtutorat: this.$route.params.idtutorat,
          },
        },
      };
    },
  },
};
</script>
